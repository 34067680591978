// extracted by mini-css-extract-plugin
export var accentColor = "#e10600";
export var container = "cta-full-width-card-module--container--42c66";
export var description = "cta-full-width-card-module--description--5e591";
export var dividerColor = "#e4e4e4";
export var dividerWhiteColor = "#fff";
export var primaryColor = "#fff";
export var secondaryColor = "#000";
export var textDisabledColor = "#ccc";
export var textPrimaryColor = "#101820";
export var textSecondaryColor = "#fff";