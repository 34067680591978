// extracted by mini-css-extract-plugin
export var accentColor = "#e10600";
export var container = "benefits-of-admission-module--container--6fe81";
export var ctaTitle = "benefits-of-admission-module--ctaTitle--0caff";
export var description = "benefits-of-admission-module--description--16b15";
export var dividerColor = "#e4e4e4";
export var dividerWhiteColor = "#fff";
export var icon = "benefits-of-admission-module--icon--15b84";
export var primaryColor = "#fff";
export var root = "benefits-of-admission-module--root--5f142";
export var secondaryColor = "#000";
export var text = "benefits-of-admission-module--text--7d8d4";
export var textDisabledColor = "#ccc";
export var textPrimaryColor = "#101820";
export var textSecondaryColor = "#fff";
export var title = "benefits-of-admission-module--title--f7826";