import * as React from 'react'
import {Layout} from "../components/Layout";
import PageNav from "../components/PageNav";
import LogoContainer from "../components/LogoContainer";
import CtaFullWidthCard from "../components/cta/CtaFullWidthCard";
import BenefitsOfAdmission from "../components/BenefitsOfAdmission";
import {MembersPageModel} from "../store/models/members-page";
import {ResidentLink} from "../store/models/resident";
import SEO from "../components/SEO";
import * as styles from "../styles/pages/members.module.scss"
import StrapiClient, {StrapiResponse, StrapiSingleTypes} from "../strapi-client/strapi-client";
import {MenuModel} from "../store/models/menu";
import {GetServerDataProps, GetServerDataReturn} from "gatsby";

type MembersPageProps = {
    serverData: {
        menu: StrapiResponse,
        page: StrapiResponse
    }
}

const Index = (props: MembersPageProps) => {
    const membersPageModel: MembersPageModel = MembersPageModel.fromJSON(props.serverData.page);
    const menuModel: MenuModel = MenuModel.fromJSON(props.serverData.menu);
    return (
        <>
            <SEO seo={membersPageModel.seo}></SEO>
            <Layout
                menu={menuModel}
                slotAboveFooter={membersPageModel.ctaCard ?
                    <CtaFullWidthCard
                        ctaCard={membersPageModel.ctaCard}
                    /> : <></>
                }
            >
                <PageNav title={membersPageModel.title} links={membersPageModel.links}
                         benefitAnchor={membersPageModel.benefitAnchor}/>
                <div className={styles.grid}>
                    {membersPageModel.residents && membersPageModel.residents.map((resident: ResidentLink) => resident.resident?.logo ?
                        <article>
                            <LogoContainer img={resident.resident?.logo}/>
                        </article>
                        : <></>
                    )}
                </div>
                <BenefitsOfAdmission label={membersPageModel.benefitTitle} items={membersPageModel.benefit}/>
            </Layout>
        </>
    )
}

export default Index;

export async function getServerData(context: GetServerDataProps): GetServerDataReturn  {
    try {
        const locale: string = context.pageContext.locale as string;
        const strapi = new StrapiClient(locale);
        const page = await strapi.getSingleType(StrapiSingleTypes.membersPage);
        const menu = await strapi.getSingleType(StrapiSingleTypes.menu);

        return {
            props: {
                page,
                menu
            },
        }
    } catch (error) {
        return {
            status: 500,
            headers: {},
            props: {}
        }
    }
}
