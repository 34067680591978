import React from "react";
import {IconArrowDown} from "./svg";
import LinkArrow from "./LinkArrow";
import * as styles from "../styles/components/benefits-of-admission.module.scss"
import StyledText, {TextStyles} from "./typography";
import {BenefitModel} from "../store/models/members-page";

type Props = {
    items?: BenefitModel[] | null,
    label?: string | null,
}

const BenefitsOfAdmission = (props: Props) => {
    const {items, label} = props;

    return (
        <section id='benefitAnchor' className={styles.root}>
            <div className={styles.ctaTitle}>
                <StyledText variant={TextStyles.h2Caps} className={styles.title}>
                    {label}
                </StyledText>
                <IconArrowDown/>
            </div>

            {items && items.map((item: BenefitModel) => (
                <article className={styles.container}>
                    <img
                        src={item.icon?.fullUrl}
                        className={styles.icon}
                        alt={item.icon?.alt}
                    />
                    <div className={styles.text}>
                        <StyledText variant={TextStyles.h2} className={styles.description}>
                            {item.title}
                        </StyledText>

                        {item.url?.url && (
                            <LinkArrow href={item.url?.link} color='accent'>
                                {item.label}
                            </LinkArrow>
                        )}
                    </div>
                </article>
            ))}

        </section>
    )
}

export default BenefitsOfAdmission;
